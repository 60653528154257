<template>
  <div>
    <template v-if="user">
      <span v-if="websocket.status" style="color: green;"> | <i class="fa fa-heart"></i></span>
      <span v-else style="color: red; cursor: pointer;" @click="refresh_page()"> | <i class="fa fa-heart"></i></span>
    </template>
    <b-modal id="d_showSocketCloseModal" v-model="d_showSocketCloseModal" title="Network Problemi" centered hide-footer no-close-on-esc centered no-stacking no-close-on-backdrop hide-header-close header-bg-variant="info" header-text-variant="dark">
      {{ $t('wdm16.10733') }}
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue';
import store from '@/store';
import { mapGetters } from 'vuex';
import {
  default as Modal
} from '@/components/widgets/Modal';
import HospitalService from '@/services/hospital';
// import VersionService from '@/services/version';
import { ClsMessenger } from '@/services/public/messenger';
import auth from '@/auth';

export default {
  name: 'WisdomSocket',
  data () {
    return {
      d_captureStream: null,
      d_messanger_data: [],
      d_networkholter_after: '',
      d_networkholter_before: '',
      d_networkholter: 0,
      show_rule_engine_modal: false,
      d_showSocketCloseModal: false,
      status: this.$t('wdm16.4097'),
      is_connected: false,
      quecue: [],
      all_in_one_list: [],
      rule_results: { rule_ignore: [], list: [], status: '' },
      refreshIntervalId: 0,
      user: JSON.parse(localStorage.getItem('user')),
      displayMediaOptions: {
        video: true,
        audio: true
      }
    };
  },
  state: {
    socket: {
      is_connected: false,
      message: ''
    }
  },
  computed: mapGetters({
    websocket: 'websocket',
    patient: 'patient_selected',
    messenger_data: 'messenger_data'
  }),
  notifications: {
    showNotification: { // You can have any name you want instead of 'showNotification'
      title: '',
      message: '',
      type: '' // You also can use 'VueNotifications.types.error' instead of 'error'
    }
  },
  mounted: function () {
    if (!this.websocket.status && this.user) {
      this.d_showSocketCloseModal = true;
    }
    this.f_networkholter();
    if (this.$options.sockets) {
      this.$options.sockets.onopen = function (event) {
        console.log('websocket onopen..:::');
        // console.log('event', event);
        // console.log('this', this);
        let patient_id = '';
        if (this.patient.id) { patient_id = this.patient.id; }
        // console.log('Vue.prototype.$socket', Vue.prototype.$socket);
        // Vue.prototype.$socket.readyState = 1;
        // this.$socket.send fonksiyonu bir kere bağlantı koptuktan sonra readyState değeri 3 yani close oluyor.
        // fakat daha sonra yeni connection başladığında readyState this.$sockette halen 3 olarak kaldığından send fonksiyonu çalışmıyor.
        // bu nedenle aktif eventten gelen websocketin readyState = 1 yani websocket open olduğundan sorunsuz bilgi gönderiyor.
        // fakat bu sefer soketin genel kullanımında hata oluyor. event değerinin storedan yönetildiğinde problem kalmaz. Ama this.$socket içerisinde websocket güncellenmesi nasıl yapacağız. bunu kontrol edelim.
        // bunun çözümü olarak Vue prototype içerisindeki socket datası sistem on open olduğunda kendisi güncellenmediğinden manuel olarak alt satırda güncelledik.
        Vue.prototype.$socket = event.target;
        // user'ı localstorage'dan yeniden almamızın sebebi kullanıcı değişimi yaptığında user datası localstorageda değişiyor.
        let user_data = JSON.parse(localStorage.getItem('user'));
        let user_send = { 'username': user_data['username'], 'hospital_group': user_data['hospital_group'], 'hospital_id': user_data['hospital_id'], 'department': user_data['department'] };
        event.target.send(JSON.stringify({ 'r_n': this.$route.name, 'p_id': patient_id, 'user': user_send, 'type': 'route_patient' }));
        store.commit('ws_status', true);
        clearInterval(this.refreshIntervalId);
        auth.last_wisdomera_version_control();
        this.f_get_messenger_data();
        let meeting_data = localStorage.getItem('meeting');
        if (meeting_data) {
          meeting_data = JSON.parse(meeting_data);
          if (meeting_data && meeting_data['id']) {
            ClsMessenger.f_getMeetingData(meeting_data['id']);
          }
        }
      };
      this.$options.sockets.onclose = function () {
        console.log('websocket onclose..:::');
        // Vue.prototype.$socket.close();
        store.commit('ws_status', false);
        // this.socket_not_connected();
      };
      this.$options.sockets.onerror = function () {
        console.log('websocket on error............');
        store.commit('ws_status', false);
        // vm.is_connected = false;
        // console.error(state, event);
        // vm.status = 'Error!!';
      };
      this.$options.sockets.onmessage = function (message) {
        let socket_data = JSON.parse(message.data);
        if (socket_data.type === 'get_my_socket_id') {
          store.commit('websocket_id', { 'id': socket_data.your_socket_id });
        }
        if (socket_data.type === 'meeting_info') {
          if (socket_data.data.type === 'leave_meeting') {
            if (socket_data.data.leave_type === 'all') {
              localStorage.removeItem('meeting');
            } else if (socket_data.data.leave_type === 'this') {
              let meeting_data = JSON.parse(localStorage.getItem('meeting'));
              for (let i in meeting_data.data.general.participant.list) {
                if (meeting_data.data.general.participant.list[i].participant_name.val.value === socket_data.data.user) {
                  meeting_data.data.general.participant.list[i].participant_status.val = { 'label': 'pasif', 'value': 'passive' };
                  break;
                }
              }
              localStorage.setItem('meeting', JSON.stringify(meeting_data));
            }
          }
          if (socket_data.data.type === 'meeting_share') {
            localStorage.setItem('meeting', JSON.stringify(socket_data.data.wdmr_69));
          }
          if (socket_data.data.type === 'join_meeting') {
            localStorage.setItem('meeting', JSON.stringify(socket_data.data.wdmr_69));
          }
        }

        if (socket_data.nhr) {
          this.d_networkholter_after = new Date().toISOString();
          let before = new Date(this.d_networkholter_before);
          before = before.getTime();
          let after = new Date(this.d_networkholter_after);
          after = after.getTime();
          let dif = after - before;
          this.$socket.send(JSON.stringify({ 'type': 'nh_dif', 'dif': dif, 'user': this.user.username }));
        }
        if (socket_data.type === 'logout') {
          this.$router.push({ path: '/logout' });
        }
        if (socket_data.type === 'reload') {
          location.reload();
        }
        if (socket_data.type === 'customer_support') {
          function file_read(blob) {
            var reader = new FileReader();
            reader.addEventListener("load", function () {
              // console.log(reader.result);
              let client_data = {
                'type': 'client_html',
                // 'client_html': client_html,
                'supporter': socket_data.supporter,
                'supporter_socket_id': socket_data.supporter_socket_id,
                'capture_stream': reader.result
              }
              vm.$socket.send(JSON.stringify(client_data));
            }, false);
            reader.readAsDataURL(blob);
          }
          let vm = this;
          // vm.d_captureStream = navigator.mediaDevices.getDisplayMedia(vm.displayMediaOptions);


          /*        async function startCapture() {
                    try {
                      vm.d_captureStream = await navigator.mediaDevices.getDisplayMedia(vm.displayMediaOptions);
                    } catch (err) {
                      console.error("Error: " + err);
                    }
                  }
                  startCapture();
                  this.d_supportHtml = setInterval(() => {
                    var mediaRecorder = new MediaRecorder(vm.d_captureStream);
                    console.log('d_supportHtml   ...');
                    if (vm.d_supportHtml) {
                      var chunks = [];
                      mediaRecorder.ondataavailable = function (e) {
                        chunks.push(e.data);
                      }
                      console.log('chunks ', chunks);
                      if (chunks.length > 0) {
                        let blob = new Blob(chunks, { type: "video/mp4" });
                        file_read(blob);
                      }
                    }
                  }, 3000);
            */
          async function startCapture() {
            await navigator.mediaDevices.getDisplayMedia({ 'audio': { 'echoCancellation': true, 'googAutoGainControl': true } })
              .then(function (stream) {
                var streamWindow = stream;
                navigator.mediaDevices.getUserMedia({ 'audio': true }).then(function (audioStream) {
                  var finalStream = new MediaStream();
                  const videoTrack = streamWindow.getVideoTracks()[0];
                  finalStream.addTrack(videoTrack);
                  const audioTrack = audioStream.getAudioTracks()[0];
                  finalStream.addTrack(audioTrack);
                  var mediaRecorder = new MediaRecorder(finalStream);
                  mediaRecorder.start();
                  var chunks = [];
                  mediaRecorder.ondataavailable = function (e) {
                    chunks.push(e.data);
                  }
                  mediaRecorder.onstop = function (e) {
                    // console.log('chunks ', chunks);
                    if (chunks.length > 0) {
                      let blob = new Blob(chunks, { type: "video/mp4" });
                      file_read(blob);
                    }
                  }
                  vm.d_supportHtml = setInterval(() => {
                    // console.log(vm.d_captureStream);
                    if (vm.d_supportHtml) {
                      mediaRecorder.stop();
                      chunks = [];
                      mediaRecorder.start();
                    } else {
                      mediaRecorder.stop();
                    }
                  }, 500);
                });

              });
          }
          startCapture();
          // var url = URL.createObjectURL(blob);
          /*
          navigator.mediaDevices.getDisplayMedia(vm.displayMediaOptions)
            .then(function (stream) {
              vm.d_captureStream = stream;
              var mediaRecorder = new MediaRecorder(stream);
              vm.d_supportHtml = setInterval(() => {
                if (vm.d_supportHtml) {
                  var chunks = [];
                  mediaRecorder.ondataavailable = function (e) {
                    chunks.push(e.data);
                  }
                  let blob = new Blob(chunks, { type: "video/mp4" });
                  file_read(blob);
                }
              }, 3000);
            });
          async function startCapture() {
            try {
              console.log('vm.d_captureStream ');
              let client_data = {
                'type': 'client_html',
                // 'client_html': client_html,
                'supporter': socket_data.supporter,
                'supporter_socket_id': socket_data.supporter_socket_id,
                'capture_stream': vm.d_captureStream
              }
              vm.$socket.send(JSON.stringify(client_data));
            } catch (err) {
              console.error("Error: " + err);
            }
          }
          this.d_supportHtml = setInterval(() => {
            if (this.d_supportHtml) {
              html2canvas(document.getElementById('bodyWisdomEra'), { 'logging': false }).then(canvas => {
                // console.log(canvas);
                // let client_html = document.getElementById('bodyWisdomEra').innerHTML;
                let dataURL = canvas.toDataURL();
                // let client_html = document.getElementById('app').innerHTML;
                let client_data = {
                  'type': 'client_html',
                  // 'client_html': client_html,
                  'supporter': socket_data.supporter,
                  'supporter_socket_id': socket_data.supporter_socket_id,
                  'canvas': dataURL
                }
                this.$socket.send(JSON.stringify(client_data));
              });
            }
          }, 3000);
          */
        }
        if (socket_data.cancel_support) {
          clearInterval(this.d_supportHtml);
          console.log('support canceled');
        }
        if (socket_data.type && socket_data.type === 'SendingText') {
          this.showNotification({ title: 'Sistem Mesajı', message: socket_data.data.SendingText, type: 'success', timeout: 10000 });
        }
        /*      if (['decision_making_system'].indexOf(socket_data.type) !== -1) {
                this.rule_results = socket_data.data;
                console.log('rule sonucu geldi', this.rule_results);
                if (this.rule_results.list.length > 0) {}
                let rule_message = this.rule_results.list.length.toString() + ' adet anlamlı algoritma tespit edildi.';
                this.showNotification({title: 'Karar Destek Sistemi', message: rule_message , type: 'success', timeout: 3000});
              } */
        if (socket_data.controller && socket_data.controller === 'none') {
          console.log('this.$route.name', this.$route.name);
          if (this.$route.name !== 'logout') {
            this.$router.push({ path: '/logout' });
          }
        }
        // console.log('socket_data', socket_data);
        if (socket_data.patient_id) {
          store.commit('ws_patient_id', socket_data.patient_id);
        }
        // tedavi listesinde yenileme yapılması gereken durumlar
        if (socket_data.type && ['days_approve', 'terminate_treatment', 'delete_treatment', 'delay_treatment', 'day_edit_save', 'treatment_save', 'protocol_note', 'note_treatment'].indexOf(socket_data.type) !== -1) {
          store.commit('ws_chemo_list', true);
        }
        // bildirim mesajı verilmek istenen durumlar
        if (socket_data.type && ['days_approve', 'terminate_treatment', 'delete_treatment', 'day_edit_save', 'protocol_note', 'note_treatment'].indexOf(socket_data.type) !== -1) {}
        if (socket_data.type && ['wisdom_data_update'].indexOf(socket_data.type) !== -1) {
          // OptionService.get_all_wisdom_data_models();
          this.get_anatomy_data();
        }
        if (socket_data.type && ['chemotherapy_protocol_delete'].indexOf(socket_data.type) !== -1) {
          let x = JSON.parse(localStorage.getItem('chemotherapy_protocols'));
          let index = '';
          for (let i in x) {
            if (x[i].value === socket_data.data.value) {
              index = i;
              break;
            }
          }
          x.splice(index, 1);
          localStorage.setItem('chemotherapy_protocols', JSON.stringify(x));
        }
        if (socket_data.type && ['chemotherapy_protocols'].indexOf(socket_data.type) !== -1) {
          console.log('chemotherapy_protocols are updating');
          let x = JSON.parse(localStorage.getItem('chemotherapy_protocols'));
          if (socket_data.data.save_type === 'new') {
            for (let pr in socket_data.data.protocol) {
              x.push(socket_data.data.protocol[pr]);
            }
          } else { // save_type === 'edit'
            let pr = socket_data.data.protocol[0];
            for (let i in x) {
              if (x[i].value === pr.value) {
                x[i] = pr;
                break;
              }
            }
          }
          localStorage.setItem('chemotherapy_protocols', JSON.stringify(x));
        }

        if (socket_data.type && ['last_bmi_update'].indexOf(socket_data.type) !== -1) {
          if (this.patient.selected && this.patient.id && this.patient.id === socket_data.patient_id) {
            store.commit('update_last_bmi', socket_data.data);
          }
        }
        if (socket_data.type && ['patient_last_laboratory'].indexOf(socket_data.type) !== -1) {
          if (this.patient.selected && this.patient.id && this.patient.id === socket_data.patient_id) {
            store.commit('patient_last_laboratory', socket_data.data);
          }
        }
        if (socket_data.type && ['last_lab_update'].indexOf(socket_data.type) !== -1) {
          if (this.patient.selected && this.patient.id && this.patient.id === socket_data.patient_id) {
            store.commit('last_lab_update', socket_data.data);
          }
        }
        if (socket_data.type && ['patient_treatment_list'].indexOf(socket_data.type) !== -1) {
          if (this.patient.selected && this.patient.id && this.patient.id === socket_data.patient_id) {
            store.commit('patient_treatment_list', socket_data.data);
          }
        }
        if (socket_data.type && ['patient_diagnosis_list'].indexOf(socket_data.type) !== -1) {
          if (this.patient.selected && this.patient.id && this.patient.id === socket_data.patient_id) {
            store.commit('patient_diagnosis_list', socket_data.data);
          }
        }

        if (socket_data.type && ['messenger_new_message'].indexOf(socket_data.type) !== -1) {
          // console.log(socket_data);
          let x = JSON.parse(JSON.stringify(this.messenger_data));
          // We are looping in talk list, and first looking for the user, then looking for the day exceeds in this user day_list
          for (let mes_ind in x) {
            let user_found = false;
            if (socket_data.data.message_data.group_key) {
              if (x[mes_ind].group && x[mes_ind].group.key == socket_data.data.message_data.group_key) {
                let det = false;
                for (let d in x[mes_ind].day_list) {
                  if (x[mes_ind].day_list[d].date === socket_data.data.message_data.messenger_data.day_list.date) {
                    x[mes_ind].day_list[d] = socket_data.data.message_data.messenger_data.day_list;
                    det = true;
                    break;
                  }
                }
                if (!det) {
                  x[mes_ind].day_list.push(socket_data.data.message_data.messenger_data.day_list);
                }
              }
            } else {
              if (x[mes_ind].user && x[mes_ind].user.username === socket_data.data.user) {
                user_found = true;
                let det = false;
                for (let d in x[mes_ind].day_list) {
                  if (x[mes_ind].day_list[d].date === socket_data.data.message_data.messenger_data.day_list.date) {
                    x[mes_ind].day_list[d] = socket_data.data.message_data.messenger_data.day_list;
                    det = true;
                    break;
                  }
                }
                if (!det) {
                  x[mes_ind].day_list.push(socket_data.data.message_data.messenger_data.day_list);
                }
              }
            }
            if (user_found) {
              break;
            }
          }
          store.commit('messenger_data', x);
        }
        /*      let data = JSON.parse(message.data);
              if (data.type === 'rule') {
                if (data.action === 'start') {
                  vm.f_notify();
                }
              } */
        this.f_showNotification(socket_data);
      };
    }
  },
  methods: {
    f_get_messenger_data: function () {
      // ClsMessenger.get_messenger_data();
    },
    f_networkholter: function () {
      if (this.$socket) {
        this.d_networkholter = setInterval(() => {
          // nh network holter
          this.d_networkholter_before = new Date().toISOString();
          this.$socket.send(JSON.stringify({ 'type': 'nh' }));
        }, 30000);
      }
    },
    socket_not_connected: function (type = '') {
      // this.is_connected = false;
      // this.update_message();
      this.refreshIntervalId = setInterval(() => {
        // bağlantı varlığını kontrol etmek amaçlı sorgulama. Bu daha sonra versiyon kontrolü gibi bir sorgu yapılabilir.
        HospitalService.get_hospital_group()
          .then(resp => {
            if (resp.data.status === 'success') {
              console.log('login yapıldı.');
              clearInterval(this.refreshIntervalId);
              // location.reload();
            }
          }, resp => {
            console.log('bağlantı yok...');
            // this.second_refresh_interval();
          });
        // Vue.use(VueNativeSock, ws_url);
      }, 5000);
    },
    second_refresh_interval: function () {
      clearInterval(this.refreshIntervalId);
      this.refreshIntervalId = setInterval(() => {
        HospitalService.get_hospital_group()
          .then(resp => {
            if (resp.data.status === 'success') {
              location.reload();
            }
          });
      }, 10000);
    },
    refresh_page: function () {
      location.reload();
    },
    send_message: function () {
      // console.log('this.$socket.readyState', this.$socket.readyState);
      this.$socket.send(JSON.stringify('deneme'));
    },
    update_message: function () {
      this.status = 'GÜNCELLEME İŞLEMİ YAPILMAKTADIR. LÜTFEN BEKLEYİNİZ.';
      this.$snotify.warning('Güncelleme işlemi başlatılmıştır', 'uyarı', {
        timeout: 5000,
        showProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true
      });
    },
    f_showNotification: function (data) {
      if (data.type === 'wdm72_results') {
        let total_results = 0;
        let msg = '';
        for (let wdm3_id in data.data.analyze_results) {
          for (let wdm72_key in data.data.analyze_results[wdm3_id]) {
            total_results += parseInt(data.data['analyze_results'][wdm3_id][wdm72_key]['total_analyze_count']);
            msg += data.data['analyze_results'][wdm3_id][wdm72_key].name + ': projesine ait analizleriniz, toplam = ' + total_results.toString();
          }
        }
        this.showNotification({ title: data.header, message: msg, type: 'success', timeout: 1000 });
      } else if (data.type === 'wdm3_preparation_completed') {
        let msg = 'Vaka listesi hazırlandı.';
        this.showNotification({ title: data.header, message: msg, type: 'success', timeout: 5000 });
      } else if (data.type === 'operation_status') {
        let msg = '';
        let notify_type = 'success';
        let notify_timeout = 5000;
        if (data.data['type'] && ['success', 'error', 'info', 'warn'].indexOf(data.data['type']) !== -1) {
          notify_type = data.data['type'].toString();
        }
        if (data.data['timeout']) {
          notify_timeout = parseInt(data.data['timeout']);
        }
        if (data.data['msg'] && data.data['msg'] !== '') {
          msg += data.data['msg'].toString();
        } else {
          let status = data.data['status'];
          let subject = data.data['subject'];
          let total_count = data.data['total_count'];
          let current_count = data.data['current_count'];
          if (status === 'completed') {
            msg += subject.toString() + ' işlemi tamamlandı';
          } else {
            msg += subject.toString() + ' işlemine ait durum bilgisi --> \n';
            msg += total_count.toString() + ' veriden ' + current_count.toString() + ' tanesi tamamlandı. İşlem henüz devam ediyor';
          }

        }
        this.showNotification({ title: data.header, message: msg, type: notify_type, timeout: notify_timeout });
      }
    },
    f_notify: function (data) {
      let vm = this;
      if (['note_treatment', 'protocol_note'].indexOf(data.type) !== -1) {
        vm.$snotify.warning(data.message, data.header, {
          timeout: 5000,
          showProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true
        });
      } else if (['days_approve', 'terminate_treatment', 'delete_treatment', 'day_edit_save', 'protocol_note', 'note_treatment'].indexOf(data.type) !== -1) {
        vm.$snotify.success(data.message + '\n' + data.user.first_name + ' ' + data.user.last_name, data.header, {
          timeout: 5000,
          showProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true
        });
      } else if (data.type === 'wdm72_results') {
        let total_results = 0;
        for (let wdm3_id in data.data.analyze_results) {
          for (let wdm72_key in data.data.analyze_results[wdm3_id]) {
            total_results += parseInt(data.data['analyze_results'][wdm3_id][wdm72_key]['total_analyze_count']);
          }
        }
        vm.$snotify.success('Analizleriniz Tamamlandı. Toplam = ' + total_results.toString(), data.header, {
          titleMaxLength: 60,
          timeout: 10000,
          showProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true
        });
      } else if (data.type === 'decision_making_system') {
        if (this.rule_results.list.length > 0) {
          let str = ' ' + this.rule_results.list.length + ' adet anlamlı algoritma sonucu tespit edildi.';
          vm.$snotify.confirm(data.message + str, data.header, {
            // bodyMaxLength: 500, çalışmadı
            titleMaxLength: 60,
            timeout: 5000,
            showProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            buttons: [{
                text: 'göster',
                action: (toast) => {
                  this.show_rule_result();
                  vm.$snotify.remove(toast.id);
                },
                bold: false
              }
              /*          {text: 'No', action: () => console.log('Clicked: No')},
                        {text: 'Later', action: (toast) => { console.log('Clicked: Later'); vm.$snotify.remove(toast.id); }},
                        {text: 'Close', action: (toast) => { console.log('Clicked: No'); vm.$snotify.remove(toast.id); }, bold: true} */
            ]
          });
        } else {
          vm.$snotify.success('Analiz tamamlandı. Algoritmalar anlamlı bir durum tespit etmedi.', data.header, {
            titleMaxLength: 60,
            timeout: 5000,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true
          });
        }
      }
      /*
      vm.$snotify.confirm('Kural detayları', 'Kural bilgisi', {
        timeout: 5000,
        showProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        buttons: [
          {text: 'Yes', action: () => console.log('Clicked: Yes'), bold: false},
          {text: 'No', action: () => console.log('Clicked: No')},
          { text: 'Later', action: (toast) => { console.log('Clicked: Later'); vm.$snotify.remove(toast.id); } },
          {text: 'Close', action: (toast) => { console.log('Clicked: No'); vm.$snotify.remove(toast.id); }, bold: true}
        ]
      });
      */
      /*
      SnotifyService.warning('Please consider patient can be colon Cancer!', 'Warning', {
        style: 'material',
        timeout: 20000,
        showProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        bodyMaxLength: 80,
        titleMaxLength: 255
      });
      */
      /*
      SnotifyService.async(this.$t('wdm16.3902'), this.$t('wdm16.3899'),
        // You should pass Promise of type SnotifyConfig to change some data or do some other actions
        () => new Promise((resolve, reject) => {
          setTimeout(() => reject({
            title: this.$t('wdm16.4443'),
            body: this.$t('wdm16.4235'),
            type: 'warning',
            config: {
              closeOnClick: true,
              timeout: this.timeout,
              showProgressBar: true,
              pauseOnHover: true,
              style: 'material',
              bodyMaxLength: 80,
              titleMaxLength: 255,
              type: 'warning'
            }
          }), 8000);
        })
      );
    */
    },
    show_rule_result: function () {
      this.show_rule_engine_modal = true;
    },
    create_all_in_one_list: function (male_anatomy) {
      this.control_in_all_in_one(male_anatomy.system.list);
      for (let sub_1 in male_anatomy.system.sub) {
        this.control_in_all_in_one(male_anatomy.system.sub[sub_1].list);
        for (let sub_2 in male_anatomy.system.sub[sub_1].sub) {
          this.control_in_all_in_one(male_anatomy.system.sub[sub_1].sub[sub_2].list);
          for (let sub_3 in male_anatomy.system.sub[sub_1].sub[sub_2].sub) {
            this.control_in_all_in_one(male_anatomy.system.sub[sub_1].sub[sub_2].sub[sub_3].list);
            for (let sub_4 in male_anatomy.system.sub[sub_1].sub[sub_2].sub[sub_3].sub) {
              this.control_in_all_in_one(male_anatomy.system.sub[sub_1].sub[sub_2].sub[sub_3].sub[sub_4].list);
              for (let sub_5 in male_anatomy.system.sub[sub_1].sub[sub_2].sub[sub_3].sub[sub_4].sub) {
                this.control_in_all_in_one(male_anatomy.system.sub[sub_1].sub[sub_2].sub[sub_3].sub[sub_4].sub[sub_5].list);
              }
            }
          }
        }
      }
      localStorage.anatomy_all_in_one = JSON.stringify(this.all_in_one_list);
    },
    control_in_all_in_one: function (liste) {
      let det = false;
      for (let i in liste) {
        for (let k in this.all_in_one_list) {
          if (this.all_in_one_list[k].value === liste[i].value) {
            det = true;
            break;
          }
        }
        if (!det) {
          this.all_in_one_list.push(JSON.parse(JSON.stringify(liste[i])));
        }
      }
    }
  },
  watch: {
    'websocket.status': function () {
      if (this.user) {
        if (!this.websocket.status) {
          // this.socket_not_connected();
          this.d_showSocketCloseModal = true;
        } else {
          this.d_showSocketCloseModal = false;
        }
      }
    },
    'patient.selected': function (selected) {
      if (this.patient.selected) {
        if (this.rule_results.patient_id !== this.patient.id) {
          this.rule_results = { rule_ignore: [], list: [], status: '' };
        }
      } else {
        this.rule_results = { rule_ignore: [], list: [], status: '' };
      }
    }
  },
  components: {
    Modal
  }
};

</script>

<style type="text/css">
div.rule_engine {
  position: fixed;
  bottom: 30px;
  right: 0px;
  width: 200px;
  padding: 3px;
  box-shadow: -2px -2px;
  border-radius: 10px;
  /*border: 3px solid #73AD21;*/
  background-color: #20a8d8;
}

</style>

